<template>
  <PageWrapper>
    <HeroBanner center eyebrow="" fullvh title="BRB. Under Construction" />
  </PageWrapper>
</template>

<script>
export default {
  name: "MaintenancePage",
};
</script>

<style scoped></style>
