<template>
  <PageWrapper>
    <!-- <HeroBanner
      style="background: var(--background-darker)"
      eyebrow="🚀 Recommended Links"
      title="A collection of links and writing to help you fall back in love typography."
      subtitle=""
    /> -->
    <!-- TMP - should be a v-for list - see resume -->
    <GridContainer style="border-block-end: var(--border)">
      <div
        class=""
        style="
          padding-block-end: var(--spacing-md);
          align-items: center;
          grid-template-columns: repeat(2, 1fr);
        "
      >
        <h2 class="" style="text-align: left">Typography</h2>
      </div>
      <div class="grid-parent">
        <ul>
          <li>
            <TextLink
              label="Handling Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="The Definitive Guide To Styling Links With CSS"
              link="https://www.smashingmagazine.com/2010/02/the-definitive-guide-to-styling-web-links/"
            />
          </li>
          <li>
            <TextLink label="Optimize Legibility" link="/" />
          </li>
          <li>
            <TextLink
              label="A Guide To Modern CSS Colors With RGB, HSL, HWB, LAB And LCH"
              link="https://www.smashingmagazine.com/2021/11/guide-modern-css-colors/"
            />
          </li>
          <li>
            <TextLink
              label="Truncation in the browser"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Design <> Development Handoff"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Drop-shadows"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Handling Typography with darkmode"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
        </ul>
        <ul>
          <li>
            <TextLink
              label="Variable Typography"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Anti-design and trends"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
        </ul>
        <ul>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
        </ul>
      </div>
    </GridContainer>

    <!-- New Section -->

    <GridContainer style="border-block-end: var(--border)">
      <div
        class=""
        style="
          padding-block-end: var(--spacing-md);
          align-items: center;
          grid-template-columns: repeat(2, 1fr);
        "
      >
        <h2 class="" style="text-align: left">Accessibility</h2>
      </div>

      <div class="grid-parent">
        <ul>
          <li>
            <TextLink
              label="Handling Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="The Definitive Guide To Styling Links With CSS"
              link="https://www.smashingmagazine.com/2010/02/the-definitive-guide-to-styling-web-links/"
            />
          </li>
          <li>
            <TextLink label="Optimize Legibility" link="/" />
          </li>
          <li>
            <TextLink
              label="A Guide To Modern CSS Colors With RGB, HSL, HWB, LAB And LCH"
              link="https://www.smashingmagazine.com/2021/11/guide-modern-css-colors/"
            />
          </li>
          <li>
            <TextLink
              label="Truncation in the browser"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Design <> Development Handoff"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Drop-shadows"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Handling Typography with darkmode"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
        </ul>
        <ul>
          <li>
            <TextLink
              label="Variable Typography"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Anti-design and trends"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
        </ul>
        <ul>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
        </ul>
      </div>
    </GridContainer>
    <GridContainer style="border-block-end: var(--border)">
      <div
        class=""
        style="
          padding-block-end: var(--spacing-md);
          align-items: center;
          grid-template-columns: repeat(2, 1fr);
        "
      >
        <h2 class="" style="text-align: left">UI Dev Docs</h2>
      </div>

      <div class="grid-parent">
        <ul>
          <li>
            <TextLink
              label="Handling Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="The Definitive Guide To Styling Links With CSS"
              link="https://www.smashingmagazine.com/2010/02/the-definitive-guide-to-styling-web-links/"
            />
          </li>
          <li>
            <TextLink label="Optimize Legibility" link="/" />
          </li>
          <li>
            <TextLink
              label="A Guide To Modern CSS Colors With RGB, HSL, HWB, LAB And LCH"
              link="https://www.smashingmagazine.com/2021/11/guide-modern-css-colors/"
            />
          </li>
          <li>
            <TextLink
              label="Truncation in the browser"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Design <> Development Handoff"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Drop-shadows"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Handling Typography with darkmode"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
        </ul>
        <ul>
          <li>
            <TextLink
              label="Variable Typography"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Anti-design and trends"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
        </ul>
        <ul>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
          <li>
            <TextLink
              label="Ligatures"
              link="https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-ligatures"
            />
          </li>
        </ul>
      </div>
    </GridContainer>
    <!-- TMP - should be a v-for list - see resume -->
  </PageWrapper>
</template>

<script>
export default {
  name: "UsefulLinks",
  components: {},
};
</script>

<style lang="sass" scoped>
.grid-parent
  grid-gap: 0
  // margin-block-end: var(--spacing-lg)
  @media only screen and (min-width: 768px)
    grid-gap: var(--spacing-lg)
ul
  list-style: none
  margin: 0
  padding: 0

li
  inline-size: 100%
  font-size: 2em
  line-height: var(--lineHeight-tall)
  // font-weight: var(--fontWeight-medium)
  padding: var(--spacing-xs) 0
  // border-block-end: var(--border)
  float: left
  list-style-type: none
  text-decoration: none
  margin: 0

li:last-child
  padding-inline-end: 0
</style>
